import React from 'react'
import LayoutComponent from '../../../components/documentation-layout/Layout'

const ProjectDashboard = () => {
  return (
    <LayoutComponent>
      <h3>Project Dashboard</h3>

      <div>
        <p>
          The Project Dashboard provides an overview of recent activity and projects.
          It allows users and admins to quickly access key information about their projects 
          and the latest updates on test cases, test cycles, and issues.
        </p>

        <h5>Key Features</h5>
        <ul>
          <li>
            <strong>Recent Projects</strong>: 
            <ul>
              <li>Users can view the projects they have been recently assigned to.</li>
              <li>Admins can see the projects they have recently created.</li>
            </ul>
          </li>
          <li>
            <strong>Activity in the Last 24 Hours</strong>:
            <ul>
              <li>Test cases added</li>
              <li>Test cycles executed</li>
              <li>Issues logged during test executions</li>
            </ul>
          </li>
        </ul>

        <p>
          This dashboard serves as a quick snapshot of project activities and helps keep both users 
          and administrators up to date with the latest testing progress and project developments.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default ProjectDashboard
